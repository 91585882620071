import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  detailbtn: {
    backgroundColor: ({ theme }) => theme.palette.button.primary,
    fontSize: "11px",
    color: ({ theme }) => theme.palette.text.primary,
    border: "2px solid #A8ABC2",
    fontWeight: 700,
    fontFamily: "Rubik",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tableWrapper: {
    height: '52vh',
    minWidth: 1450,
  },
  compliancystatus: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  statusYes: {
    color: "green",
    marginRight: "8px",
  },

  statusNo: {
    color: "rgb(191,38,38)",
    marginRight: "8px",
  },

  unknownStatus: {
    color: "rgb(170,170,10)",
    marginRight: "8px",
  },

  statusCode: {
    width: "15px",
    height: "15px",
    borderRadius: "3px",
    marginRight: "10px",
  },

  statusText: {
    width: "40px",
    height: "15px",
    color: ({ theme }) => theme.palette.text.primary,
    textAlign: "left",
    marginTop: "4px",
  },
  form: {
    width: "10vw",
    display: "flex",
    justifyContent: "center",
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #A8ABC2",
    borderRadius: "5px",
    height: "35px",
    width: "12rem",
    backgroundColor: ({ theme }) => theme.palette.bgColor,
    color: ({ theme }) => theme.palette.text.primary,
    boxShadow: "none",
  },
  compliantToShipment: {
    width: ({ isNavbarOpen }) => (isNavbarOpen ? "12rem" : "14rem"),
    height: "35px",
    marginRight: ({ isNavbarOpen }) => (isNavbarOpen ? "5rem" : "6rem"),
    display: "flex",
    justifyContent: "center",
  },
  commonDropDownStyle: {
    width: "200px",
    height: "35px",
    marginRight: 0,
    display: "flex",
    justifyContent: "center",
  },

  calendarAnchor: {
    marginTop: "-60px",
    position: "absolute",
    left: "56%",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    zIndex: "100",
  },
  active: {
    transform: "translateY(0)",
    transition: "400ms ease",
    opacity: 1,
    visibility: "visible",
  },
  inactive: {
    transform: "translateY(-30px)",
    transition: "400ms ease",
    opacity: 0,
    visibility: "hidden",
  },
  calendarInput: {
    position: "relative",
  },
  btn: {
    backgroundColor: ({ theme }) => theme.palette.bgColor,
    color: ({ theme }) => theme.palette.text.primary,
    fontSize: "14px !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    lineHeight: "150% !important",
    padding: "0px",
    paddingRight: 0,
    width: "13rem",
    height: "35px",
    paddingInline: "10px",
    border: "1px solid rgb(168, 171, 194, 0.4)",
    borderRadius: "5px",
    display: "flex",
    boxShadow: "none",
    textTransform: "none",
    justifyContent: "center",
  },
  actionBtn: {
    backgroundColor: "#5b5d6e",
    borderRadius: "5px",
    cursor: "pointer",
    marginRight: "0px !important",
    height: "32px",
    width: "38px",
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    marginTop: "auto",
    marginBottom: "auto",
    "&:hover": {
      backgroundColor: "#5b5d6e",
    },
  },
  lastQueryTimeContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: "1rem",
    marginLeft: "10px",
    marginBottom: "2rem",
    paddingBottom: "0",
  },
  lastQueryTimeText: {
    color: ({ theme }) => theme.palette.text.primary,
  },
  actionBtns: {
    display: "flex",
    justifyContent: "space-between",
    width: 130,
  },
});
