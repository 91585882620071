import { createRef, useEffect, useState } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import { useStyles } from "./styles";
import { useTheme } from "@emotion/react";

const registeredLanguages = {};

const Highlight = ({ language = "json", children }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [loaded, setLoaded] = useState(false);
  const codeNode = createRef();

  useEffect(() => {
    hljs.highlightAll();
  }, []);

  useEffect(() => {
    if (language && !registeredLanguages[language]) {
      try {
        const newLanguage = require(`highlight.js/lib/languages/${language}`);
        hljs.registerLanguage(language, newLanguage);
        registeredLanguages[language] = true;
        setLoaded(true);
        hljs.highlightElement(codeNode.current);
      } catch (e) {}
    } else {
      setLoaded(true);
    }
  }, [codeNode, language, loaded]);

  return (
    <pre className={classes.profile} data-testid="highlight">
      <code ref={codeNode} className={language} data-testid="codeBlock">
        {children}
      </code>
    </pre>
  );
};

export default Highlight;
